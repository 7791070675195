/** @format */

import React, { useEffect } from "react";
import "./Know.css";
import Surgeries from "./Dropdown/Surgeries";
import Imagesec from "./Dropdown/Imagesec";
import diabaties_img from "../Images/webp/diabaties_11zon.webp";
import diabatiestest_img from "../Images/webp/a-phlebologist-does-an-ultrasound-of-the-veins-of-2023-11-27-05-04-45-utc.webp";
import Mainfooter from "./Mainfooter";
import style from "./know.module.css"
import Appointement from "./Appointement";
import Form from "./Form";

const Know = () => {
  const txt =
    "November is Diabetes Awareness Month, a fine time to learn about diabetes and to take the steps necessary to protect your health. If not managed properly, diabetes may cause serious problems affecting your blood vessels and feet. For this purpose, Asian Vascular Hospital will be helping with its special health checkup for the early detection of these issues so you to act on them early.";
  const image = diabaties_img;
  const image_2 = diabatiestest_img;

  const txt2 =
    "Early detection of problems means effective management of diabetes. This evaluation will help you understand your health so that you might take corrective measures to avoid major complications of diabetes. It may even help you stay healthier and avoid major diabetes-related problems.";

  const sur_head = "Diabetes Awareness Month – Take Care of Yourself";
  useEffect(() => {
    document.title = "AVH - Diabetes Awareness";
  }, []);

  return (
    <div>
      <div className="sur_head_page">
        <Surgeries sur_head={sur_head} />
      </div>

      <div className="image_component">
        <Imagesec txt={txt} image={image} />
      </div>

      <section className="art_sec" data-aos="fade-up">
        <div className="art_txt_main">
          <h3 className="know_head">
            What's Included in Our Diabetes Health Evaluation?
          </h3>
          <p className="know_txt">
            Our package for evaluation is designed to look out for the early
            signs of complications that diabetes can cause. Here's what you will
            get:
          </p>
          <div className="art_txt">
            <ol className="know_list">
              <li>
                <b>Screening for Peripheral Artery Disease</b>{" "}
                <span>
                  Diabetes is one of the risk factors for PAD, which involves
                  the narrowing of the blood vessels supplying blood to your
                  legs. It might cause pain or make walking difficult. Our
                  screening helps find early signs of PAD so you can keep off
                  serious issues.
                </span>
              </li>
              <li>
                <b>Evaluation of Diabetic Foot</b>{" "}
                <span>
                  Diabetes can affect your feet because poor circulation and
                  nerve damage may impede healing and increase the risk of
                  infection. We check your feet for signs of these issues to
                  help prevent wounds or infections.
                </span>
              </li>
              <li>
                <b>ABI-Ankle-Brachial Index Test</b>{" "}
                <span>
                  Blood pressure in your ankle is compared to that in your arm
                  in this test to check how well blood is flowing. It's a
                  simple, quick test to help find blockages in the arteries of
                  your legs.
                </span>
              </li>
              {/* <li><b>Femero Distal Bypass:</b> <span>Addressing peripheral arterial disease, our hospital offers Femero Distal Bypass surgeries to enhance blood flow to the lower extremities. With a focus on precision and patient well-being, our experienced vascular surgeons utilize advanced technologies to achieve optimal results in this intricate procedure.</span></li> */}
            </ol>
          </div>
        </div>
      </section>

      <section>
        <div className="art_txt_main art_txt_main_2">
          <h3 className="know_head">Full Blood Glucose Analysis</h3>
          <p className="know_txt know_txt_2">
            Management of blood sugar is a very vital component of diabetes
            management. Our package comprises:
          </p>
          <div className="art_txt art_txt_2">
            <ul>
              <li>
                <b>Random Blood Sugar (RBS):</b>{" "}
                <span>
                  It is a test used to measure blood sugar at any time to give
                  an idea about what your glucose levels are at that particular
                  instant.
                </span>
              </li>
              <li>
                <b>FBS (Fasting Blood Sugar): </b>{" "}
                <span>
                  It is a test to check the level of sugar in the blood after
                  fasting, which gives insight into how well your body manages
                  glucose throughout the night time.
                </span>
              </li>
              <li>
                <b>PLBS (Postprandial Blood Sugar): </b>{" "}
                <span>
                  {" "}
                  post-meal blood sugar will give you an idea of how your system
                  is coping with a particular food.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="art_sec know_sec know_sec_2" data-aos="fade-up">
        <div className="art_txt_main">
          <h3 className="know_head know_head_2">Why Testing Early Matters</h3>
        </div>
      </section>

      <div className="image_component know_image">
        <Imagesec txt={txt2} image={image_2} />
      </div>

      <section className="art_sec know_sec " data-aos="fade-up">
        <div className="art_txt_main">
          <h3 className="know_head ">
            Take Charge This Diabetes Awareness Month
          </h3>
          <p className="know_txt_2 ">
            So, don't wait until symptoms appear. This Diabetes Awareness Month
            put your health first with our special evaluation at Asian Vascular
            Hospital. Book your Screening Today. Take immediate action to
            protect your vascular health. <b>Tests valued at ₹5,000</b> are
            designed to detect early signs of vascular and diabetic
            complications.
          </p>
        </div>
        <div>
            <Form/>
        </div>
        {/* <div>
          <h5 style={{ color: "#cf0000", textAlign: "center",marginTop:"20px" }}>
            visit us at our free diabetes camp location
          </h5>
          <div className="foot_form">
                            <div className="">
                            </div>
                            <div className={style.foot_add}>
                                
                                <div>
                                <p >Asian Vascular Hospital 8-2-676 2/B </p>
                                 <p> Road No. 12, Sri Ram Nagar Colony,</p>
                                 <p> Banjara Hills,</p>
                                 <p>Hyderabad, Telangana – 500034</p>
                                 </div>
                            </div>
                   
                        </div>
        </div> */}
      </section>

      <div className="know_footer">
        <Mainfooter />
      </div>
    </div>
  );
};

export default Know;
