import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './Dashboard.css'

const Dashboard = () => {

    const [bot_data,setbot_data] = useState([])

    const get_data = async ()=>{
        await axios.get('https://avh-database-default-rtdb.firebaseio.com/form_data.json')
        .then(res=>setbot_data(res.data))
       
    }
    useEffect(()=>{
        get_data()
    },[])



    return (
        <div>
            <div className="dash">
                <h1 className='mb-3'>Dashboard</h1>
                <table>
                    <tr>
                        {/* <th>Name</th>
                        <th>Age</th>
                        <th>Symptoms</th>
                        <th>Habits & Conditions</th>
                        <th>Phone Number</th>
                        <th>Email</th>
                        <th>Location</th>
                        <th>Book Appointment</th>
                        <th>Register Date</th>
                        <th>Register Time</th> */}
                        <th>First Name</th>
                        <th>Family Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Questions</th>
                        <th>Subject</th>
                    </tr>
                    
                        {Object.entries(bot_data).map(([key,e])=>{
                            return(
                                <tr key={key}>
                                    {/* <td>{e.name}</td>
                                    <td>{e.age}</td>
                                    <td>{e.Symptoms}</td>
                                    <td>{e.Habits_Conditions}</td>
                                    <td>{e.Phone_Number}</td>
                                    <td>{e.email}</td>
                                    <td>{e.Location}</td>
                                    <td>{e.Appointment}</td>
                                    <td>{e.Register_date}</td>
                                    <td>{e.Register_time}</td> */}

                                    <td>{e.First_Name}</td>
                                    <td>{e.Family_Name}</td>
                                    <td>{e.Email}</td>
                                    <td>{e.Phone_Number}</td>
                                    <td>{e.Questions}</td>
                                    <td>{e.Subject}</td>
                                </tr>
                            )
                        })}
                    
                </table>
            </div>
        </div>
    );
};

export default Dashboard;