/** @format */

import React, { useEffect, useState } from "react";
import "./Appointment.css";
import axios from "axios";
// import Doctors from './Doctors';

import style from "./know.module.css";

const Form = () => {
  const img =
    "https://www.eternalhospital.com/wp-content/02/book-an-appointment.jpg";
  const txt = "Appointment";

  const [data, setdata] = useState({
    First_Name: "",
    Family_Name: "",
    Phone_Number: "",
    Email: "",
    Subject: "",
    Questions: "",
  });

  const handledata =  (e) => {
    setdata({ ...data, [e.target.name]: e.target.value });
  };
  const handlesubmit = async (e) => {
    e.preventDefault();
    // await axios.post('https://api.sheetbest.com/sheets/1c949d21-6149-4fc2-912f-a1e763e3310c',data)
    await axios.post('https://avh-database-default-rtdb.firebaseio.com/form_data.json',data).then(res => console.log("success"))
    setdata({
      First_Name: "",
      Family_Name: "",
      Phone_Number: "",
      Email: "",
      Subject: "",
      Questions: "",
    });
  };

  // const aa = async ()=>{
  //     const api = await axios.post('https://script.google.com/macros/s/AKfycby2jiMNfekEpGWSJUUbfYhmpaSg2JtIz1p0uNjTUrL32FYn-4uwvgF5cltpr9ZjcXqh/exec')
  //     console.log(api.data)
  // }

  useEffect(() => {
    document.title = "AVH - Appointment";
    // aa()
  }, []);
console.log(data)
  return (
    <div>
      {/* <div className="contact_req">
                <Doctors2 image={img} txt={txt}/>
                </div> */}

      <section className="appoint_cmd">
        <div className="appoint_main">
          {/* <img src={'https://images.pexels.com/photos/7108317/pexels-photo-7108317.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'} alt="" /> */}

          <div className="cnt_main" data-aos="fade-up">
            <div className="cnt_sub">
              <div className="cnt_hd">
                <h1>Book Your Appointment Now</h1>

                <form className="cnt_cnt_form" onSubmit={handlesubmit}>
                  <input
                    type="text"
                    placeholder="First Name"
                    onChange={handledata}
                    name="First_Name"
                    value={data.First_Name}
                    required
                  />
                  <input
                    type="text"
                    placeholder="Family Name"
                    onChange={handledata}
                    name="Family_Name"
                    value={data.Family_Name}
                    required
                  />
                  <input
                    type="tel"
                    placeholder="Phone Number"
                    onChange={handledata}
                    name="Phone_Number"
                    value={data.Phone_Number}
                    pattern="^\d{10}$"
                    maxLength={10}
                    required
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    onChange={handledata}
                    name="Email"
                    value={data.Email}
                    // required
                  />
                  <input
                    type="text"
                    placeholder="Subject"
                    onChange={handledata}
                    name="Subject"
                    value={data.Subject}
                    required
                  />
                  <textarea
                    name="Questions"
                    id=""
                    placeholder="Questions"
                    onChange={handledata}
                    value={data.Questions}
                    required
                  ></textarea>
                  <button className="btn send_btn" type="submit">
                    <i className="fa-solid fa-envelope"></i> Send
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className={style.address}>
            <h5
       
            >
              Visit Us At Our Free Diabetes Camp Location
            </h5>
            <div className="foot_form">
              <div className={style.foot_add}>
                <div>
                  <p>Asian Vascular Hospital 8-2-676 2/B </p>
                  <p> Road No. 12, Sri Ram Nagar Colony,</p>
                  <p> Banjara Hills,</p>
                  <p>Hyderabad, Telangana – 500034</p>

                  <p>
                    <b>Phone :</b> +91 733 732 1814
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Form;
