import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import './Nav.css'
// import nav_logo from '../Images/hospital_logo.png'
import nav_logo from '../Images/AVH_Logo_Rework_1.webp'
import axios from "axios";


const Nav = () => {

  const [data,setdata] = useState({
    First_Name:'',
    Family_Name:'',
    Phone_Number:'',
    Email:'',
    Subject:'',
    Questions:''
})
const donevis = useRef()

const handledata = (e) =>{
    setdata({...data,[e.target.name]:e.target.value})
}
const handlesubmit = async(e) =>{
  e.preventDefault()
    await axios.post('https://avh-database-default-rtdb.firebaseio.com/form_data.json',data)
    setdata({
        First_Name:'',
        Family_Name:'',
        Phone_Number:'',
        Email:'',
        Subject:'',
        Questions:''
    })
    donevis.current.style.display = 'block'
}

const donehide =  () =>{
  donevis.current.style.display = 'none'

}

    const bar = useRef()
    const menu_style = useRef()
    const head_scroll = useRef()
    const drop_sub_1 = useRef()
    const drop_sub_2 = useRef()
    const formdis = useRef()


    const menu_vis = ()=>{
        bar.current.classList.toggle('visible')
        menu_style.current.classList.toggle('fa-x')
    }
    const imageSize = useRef()

    window.onscroll=()=>{
        if(window.scrollY>0){
            head_scroll.current.style.background = '#fff'
            head_scroll.current.style.transition = '0.3s'
            drop_sub_1.current.classList.add('drop_add')
            drop_sub_2.current.classList.add('drop_add')
            imageSize.current.classList.add('imageSize')

        }
        else{
            head_scroll.current.style.background = 'none'
            drop_sub_1.current.classList.remove('drop_add')
            drop_sub_2.current.classList.remove('drop_add')
            imageSize.current.classList.remove('imageSize')

        }
    }

    useEffect(()=>{
      setTimeout(()=>{
      formdis.current.classList.add('formvis')
      },25000)
    },[])

    const formopen = () =>{
      formdis.current.classList.add('formvis')
    }
    const forminvis = () =>{
      formdis.current.classList.remove('formvis')
    }
  return (
    <div>
      <header className="d-flex align-items-center justify-content-between" ref={head_scroll}>
        <div className="">
          <Link to={'/'}><img className="logo_img" src={nav_logo} alt="" ref={imageSize} style={{objectFit:"cover"}}/></Link>
        </div>
        <div className="d-flex align-items-center gap-4">
          <ul className="nav_links " ref={bar}>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>
              <Link to={"/About"}>About</Link>
            </li>
            <li>
              <Link to={"/Ourdoctors"}>Our Doctors</Link>
            </li>
            <li className="drop_menu_main">Departments <i className="fa-solid fa-caret-down" style={{color:'#2A3D53'}}></i>
              <ul className="drop_menu_sub" ref={drop_sub_1}>
                <li><Link className="drop_a" to={'/Pheriperal'}>Peripheral Vascular Sciences</Link></li>
                <li><Link className="drop_a" to={'/Cardiovascular'}>Cardiovascular Sciences / CT Surgeries</Link></li>
                <li><Link className="drop_a" to={'/Pulmonology'}>Pulmonology</Link></li>
              </ul>
            </li>
            <li className="drop_menu_main" >Sugeries <i className="fa-solid fa-caret-down" style={{color:'#2A3D53'}}></i>
              <ul className="drop_menu_sub" ref={drop_sub_2}>
                <li><Link className="drop_a" to={'/venous'}>Venous surgeries</Link></li>
                <li><Link className="drop_a" to={'/Arterial'}>Arterial surgeries</Link></li>
                <li><Link className="drop_a" to={'/Endovascular'}>Endovascular Arterial Procedures</Link></li>
                <li><Link className="drop_a" to={'/Cerebrovascular'}>Cerebrovascular procedures</Link></li>
                <li><Link className="drop_a" to={'Renovascular'}>Renovascular procedures</Link></li>
                <li><Link className="drop_a" to={'/Cardio'}>Cardiovascular procedures</Link></li>
              </ul>
            </li>
            {/* <li>
              <Link to={""}>Sugeries </Link>
            </li> */}
            <li>
              <Link to={"/Contact"}>Contact</Link>
            </li>
          </ul>
          <button className="btn book p-2" onClick={formopen}>Book Appointment</button>
          <div className="" id="menu_btn">
          <i className="fa-solid fa-bars" onClick={menu_vis} ref={menu_style}></i>
          </div>
        </div>
      </header>

            <div className="nv_cntct_main" ref={formdis}>
           <div className="cnt_main  nv_cnct" data-aos="zoom-in" >
                            <div className="cnt_sub">
                                <div className="cnt_hd d-grid justify-content-center">
                                  <div className="text-end mb-1" onClick={forminvis}>
                                    <i className="fa-solid fa-x form_wrng" style={{marginTop:'-20px'}}></i>
                                  </div>

                                    <h1 style={{textAlign:'center'}}>Book Your Appointment</h1>

                                    <form className="cnt_cnt_form " onSubmit={handlesubmit}>
                                    <input type="text" placeholder='First Name' onChange={handledata} name='First_Name' value={data.First_Name} required/>
                                        <input type="text" placeholder='Family Name' onChange={handledata} name='Family_Name' value={data.Family_Name} required/>
                                        <input type="tel" placeholder='Phone Number' onChange={handledata} name='Phone_Number' value={data.Phone_Number} pattern="^\d{10}$" maxLength={10} required/>
                                        <input type="email" placeholder='Email' onChange={handledata} name='Email' value={data.Email} />
                                        <input type="text" placeholder='Subject' onChange={handledata} name='Subject' value={data.Subject} required/>
                                        <textarea name="Questions" id="" placeholder='Questions' onChange={handledata}  value={data.Questions} required></textarea>
                                        <button className='btn send_btn send_btn_2' type="submit"><i className='fa-solid fa-envelope' ></i> Book Appointment</button>
                                    </form>
                                </div>
                            </div>
              </div>
              </div>

              <div className="done_main" ref={donevis}>
              <div className="done">
              <i className="fa-solid fa-check"></i>
                  <span>Booked Successfully</span>
                <button className="btn send_btn" onClick={donehide}>Done</button>
              </div>
              </div>
    </div>
  );
};

export default Nav;
